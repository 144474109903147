<template>
    <el-tabs v-model="active" @tab-click="getInfo()">
        <el-tab-pane label="消息设置" name="0" class="descriptionsBox">
            <el-descriptions title="电子档案借阅"></el-descriptions>
            <el-descriptions :column="3" v-if="info.electronic.audit">
                <el-descriptions-item label="审核处理通知">
                    <el-radio-group v-model="info.electronic.audit.remind">
                        <el-radio label="0">开启</el-radio>
                        <el-radio label="1">关闭</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="通知方式" v-if="info.electronic.audit.remind == 0">
                    <el-checkbox label="系统消息" v-model="info.electronic.audit.systemReminder" true-label="0" false-label="1" @change="changeReminder(info.electronic.audit, 'phoneReminder')"></el-checkbox>
                    <el-checkbox label="手机短信" v-model="info.electronic.audit.phoneReminder" true-label="0" false-label="1" @change="changeReminder(info.electronic.audit, 'systemReminder')"></el-checkbox>
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions :column="3" v-if="info.electronic.result">
                <el-descriptions-item label="审核结果通知">
                    <el-radio-group v-model="info.electronic.result.remind">
                        <el-radio label="0">开启</el-radio>
                        <el-radio label="1">关闭</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="通知方式" v-if="info.electronic.result.remind == 0">
                    <el-checkbox label="系统消息" v-model="info.electronic.result.systemReminder" true-label="0" false-label="1" @change="changeReminder(info.electronic.result, 'phoneReminder')"></el-checkbox>
                    <el-checkbox label="手机短信" v-model="info.electronic.result.phoneReminder" true-label="0" false-label="1" @change="changeReminder(info.electronic.result, 'systemReminder')"></el-checkbox>
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions :column="3" class="m_b2" v-if="info.electronic.expire">
                <el-descriptions-item label="到期提醒通知">
                    <el-radio-group v-model="info.electronic.expire.remind">
                        <el-radio label="0">开启</el-radio>
                        <el-radio label="1">关闭</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="通知方式" v-if="info.electronic.expire.remind == 0">
                    <el-checkbox label="系统消息" v-model="info.electronic.expire.systemReminder" true-label="0" false-label="1" @change="changeReminder(info.electronic.expire, 'systemReminder', 1)"></el-checkbox>
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="实体档案借出利用" :column="3" class="m_b2">
                <el-descriptions-item label="到期提醒通知" v-if="info.lend.expire">
                    <el-radio-group v-model="info.lend.expire.remind">
                        <el-radio label="0">开启</el-radio>
                        <el-radio label="1">关闭</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="通知方式" v-if="info.lend.expire && info.lend.expire.remind == 0">
                    <el-checkbox label="系统消息" v-model="info.lend.expire.systemReminder" true-label="0" false-label="1" @change="changeReminder(info.lend.expire, 'phoneReminder')"></el-checkbox>
                    <el-checkbox label="手机短信" v-model="info.lend.expire.phoneReminder" true-label="0" false-label="1" @change="changeReminder(info.lend.expire, 'systemReminder')"></el-checkbox>
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="实体档案内部利用" :column="3" class="m_b2">
                <el-descriptions-item label="到期提醒通知" v-if="info.interior.expire">
                    <el-radio-group v-model="info.interior.expire.remind">
                        <el-radio label="0">开启</el-radio>
                        <el-radio label="1">关闭</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="通知方式" v-if="info.interior.expire && info.interior.expire.remind == 0">
                    <el-checkbox label="系统消息" v-model="info.interior.expire.systemReminder" true-label="0" false-label="1" @change="changeReminder(info.interior.expire, 'phoneReminder')"></el-checkbox>
                    <el-checkbox label="手机短信" v-model="info.interior.expire.phoneReminder" true-label="0" false-label="1" @change="changeReminder(info.interior.expire, 'systemReminder')"></el-checkbox>
                </el-descriptions-item>
            </el-descriptions>
            <el-button class="saveBtn" type="primary" @click="submitMessageSet()" size="small">保存</el-button>
        </el-tab-pane>
        <el-tab-pane label="审核设置" name="1">
            <Audit :activeTab="0" v-if="active == 1"></Audit>
        </el-tab-pane>
        <el-tab-pane label="借阅设置" name="2" class="descriptionsBox">
            <el-form size="small" :model="inputForm" :rules="rules" ref="inputForm" label-width="160px" v-loading="loading">
                <el-descriptions title="电子档案借阅"></el-descriptions>
                <el-form-item prop="dzMaxNumber" label="借阅上限：">
                    <el-input v-model.trim="inputForm.dzMaxNumber" maxlength="3" placeholder="请输入电子档案单次借阅上限（1-999）"
                              clearable style="width: 60%" @input="getNumType('dzMaxNumber')"></el-input>
                </el-form-item>
                <el-descriptions title="实体档案借阅" class="p_t2"></el-descriptions>
                <el-form-item prop="readMaxNumber" label="阅览利用借阅上限：">
                    <el-input v-model.trim="inputForm.readMaxNumber" maxlength="3" placeholder="请输入阅览利用单次借阅上限（1-999）"
                              clearable style="width: 60%" @input="getNumType('readMaxNumber')"></el-input>
                </el-form-item>
                <el-form-item prop="lendMaxNumber" label="借出利用借阅上限：">
                    <el-input v-model.trim="inputForm.lendMaxNumber" maxlength="3" placeholder="请输入借出利用单次借阅上限（1-999）"
                              clearable style="width: 60%" @input="getNumType('lendMaxNumber')"></el-input>
                </el-form-item>
                <el-form-item prop="interiorMaxNumber" label="内部利用借阅上限：">
                    <el-input v-model.trim="inputForm.interiorMaxNumber" maxlength="3" placeholder="请输入内部利用单次借阅上限（1-999）"
                              clearable style="width: 60%" @input="getNumType('interiorMaxNumber')"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="saveBtn" type="primary" @click="submit()" size="small">保存</el-button>
                </el-form-item>
            </el-form>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
    import Audit from './audit'
    export default {
        components: {
            Audit
        },
        data() {
            return {
                loading: false,
                active: '0',
                info: {
                    electronic: {},
                    lend: {},
                    interior: {},
                },
                inputForm: {
                    dzMaxNumber: null,
                    interiorMaxNumber: null,
                    lendMaxNumber: null,
                    readMaxNumber: null,
                },
                rules: {
                    dzMaxNumber: [
                        {required: true, message: '请输入借阅上限', trigger: 'blur'},
                        {validator: this.validator.numLimit, trigger: 'blur'}
                    ],
                    interiorMaxNumber: [
                        {required: true, message: '请输入借阅上限', trigger: 'blur'},
                        {validator: this.validator.numLimit, trigger: 'blur'}
                    ],
                    lendMaxNumber: [
                        {required: true, message: '请输入借阅上限', trigger: 'blur'},
                        {validator: this.validator.numLimit, trigger: 'blur'}
                    ],
                    readMaxNumber: [
                        {required: true, message: '请输入借阅上限', trigger: 'blur'},
                        {validator: this.validator.numLimit, trigger: 'blur'}
                    ]
                },
            }
        },
        mounted() {
            this.getInfo()
        },
        methods: {
            getInfo() {
                if(this.active == 0) {
                    this.getMessageSet()
                }
                if(this.active == 2) {
                    this.getBorrowSet()
                }
            },
            // 获取消息设置
            getMessageSet() {
                this.loading = true
                this.$axios(this.api.allCommon.getByMessageType, {
                    systemType: '2',
                    messageType: ['3', '4', '5']
                }, 'post').then(data => {
                    if (data.data && data.status) {
                        let electronic = {}, lend = {}, interior = {}
                        data.data.map(item => {
                            switch (item.messageType) {
                                case "3":
                                    if (item.notificationType == '0') {
                                        electronic.audit = item
                                    }
                                    if (item.notificationType == '1') {
                                        electronic.result = item
                                    }
                                    if (item.notificationType == '2') {
                                        electronic.expire = item
                                    }
                                    break;
                                case "4":
                                    if (item.notificationType == '0') {
                                        lend.audit = item
                                    }
                                    if (item.notificationType == '1') {
                                        lend.result = item
                                    }
                                    if (item.notificationType == '2') {
                                        lend.expire = item
                                    }
                                    break;
                                case "5":
                                    if (item.notificationType == '0') {
                                        interior.audit = item
                                    }
                                    if (item.notificationType == '1') {
                                        interior.result = item
                                    }
                                    if (item.notificationType == '2') {
                                        interior.expire = item
                                    }
                                    break;
                            }
                        })
                        this.$nextTick(() => {
                            this.$set(this.info, 'electronic', electronic)
                            this.$set(this.info, 'lend', lend)
                            this.$set(this.info, 'interior', interior)
                            this.loading = false
                        })
                    }
                })
            },
            changeReminder(item, type, num) {
                if(item.messageType == 3 && num && item.systemReminder == 1) {
                    this.$message.warning('通知方式必选')
                    this.$set(item, 'systemReminder', '0')
                }
                if(item.systemReminder == 1 && item.phoneReminder == 1) {
                    this.$message.warning('通知方式必选')
                    this.$set(item, type, '0')
                }
            },
            // 保存消息设置
            submitMessageSet() {
                let p = [], obj = []
                for(let key in this.info){
                    p.push(this.info[key])
                }
                p.map(item => {
                    for(let key in item){
                        obj.push(item[key])
                    }
                })
                this.$axios(this.api.allCommon.updateByType, obj, 'put').then(data => {
                    if (data.status) {
                        this.$message.success('提交成功')
                    } else {
                        this.$message.error('提交失败')
                    }
                    this.getMessageSet()
                })
            },
            // 获取借阅设置
            getBorrowSet() {
                this.loading = true
                this.$axios(this.api.record.getByMaxNumber).then(data => {
                    if (data.status) {
                        this.recover(this.inputForm, data.data)
                        this.loading = false
                    }
                })
            },
            getNumType(type) {
                let regular = /[^\d]/g
                if (this.inputForm[type]) {
                    this.$set(this.inputForm, type, this.inputForm[type].replace(regular, ''))
                }
            },
            // 保存
            submit() {
                if(this.active == 2) {
                    this.submitBorrowSet()
                }
            },
            // 保存水印设置
            submitBorrowSet() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.$axios(this.api.record.updateMaxNumber, this.inputForm, 'put').then(data => {
                            if (data.status) {
                                this.$message.success('保存成功')
                            } else {
                                this.$message.error('保存失败')
                            }
                            this.getBorrowSet()
                            this.loading = false
                        })
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .descriptionsBox {
        padding: 20px;
        overflow-y: auto;
        height: calc(100vh - 300px);
    }
    .descriptionsBox >>> .el-radio-group {
        margin-top: 4px !important;
    }
    .saveBtn {
        position: fixed;
        bottom: 20px;
        left: 55%;
    }
</style>
